// Nav
export const LAYOUT_DOCK_NAV_TOGGLE = "LAYOUT_DOCK_NAV_TOGGLE";
export const LAYOUT_DOCK_NAV_OPEN = "LAYOUT_DOCK_NAV_OPEN";
export const LAYOUT_DOCK_NAV_CLOSE = "LAYOUT_DOCK_NAV_CLOSE";
export const LAYOUT_DOCK_NAV_AUTO_TOGGLE = "LAYOUT_DOCK_NAV_AUTO_TOGGLE";
export const LAYOUT_DOCK_NAV_AUTO_ENABLE = "LAYOUT_DOCK_NAV_AUTO_ENABLE";
export const LAYOUT_DOCK_NAV_AUTO_DISABLE = "LAYOUT_DOCK_NAV_AUDO_DISABLE";

// Layout
export const LAYOUT_BOXED_TOGGLE = "LAYOUT_BOXED_TOGGLE";
export const LAYOUT_BOXED_ENABLE = "LAYOUT_BOXED_ENABLE";
export const LAYOUT_BOXED_DISABLE = "LAYOUT_BOXED_DISABLE";

// Theme
export const THEME_TOGGLE = "THEME_TOGGLE";
