export enum PageName {
	HOME="home",
	JP_CLASSES="jpClasses",
	USERS="users",
	USERS_EDIT="users_edit",
	USERS_NEW="users_new",
	STAGGERED_ORDER="staggered_order",
	MANAGE_INSTRUCTORS="manage_instructors",
	MANAGE_TAGS = "manage_tags",
	MANAGE_HIGH_SCHOOLS = "manage_high_schools",
	MANAGE_CLASS_LOCATIONS = "manage_class_locations",
	MANAGE_DONATION_FUNDS = "manage_donation_funds",
	DOCK_REPORT = "dock_report",
	DOCK_HOUSE_PAGE = "dock_house_page",
	SIGNOUTS_TABLES = "signouts_tables",
	MANAGE_PERMISSIONS = "manage-permissions",
	MANAGE_ACCESS = "manage-access",
	SALES_DASHBOARD = "sales_dashboard",
	INCIDENTS = "incidents",
	CLASSES="classes",
	FOTV_CONTROLLER="fotv_controller"
}
