// Autogenerated from backend (run main of org.sailcbi.APIServer.Entities.access.CbiAccessUtil)
export const PERMISSIONS = { 
	PERM_MANAGE_USERS_SCREEN: 1024,
	PERM_MANAGE_ACCESS: 1025,
	PERM_UPDATE_JP_INSTRUCTORS: 3000,
	PERM_UPDATE_JP_CLASS_LOCATIONS: 3001,
	PERM_UPDATE_SCHOOLS: 5000,
	PERM_SIGN_DOCK_REPORT: 6000,
	PERM_UPDATE_DOCK_REPORT_RESTRICTED: 6001,
	PERM_UPDATE_EVENTS: 8000,
	PERM_UPDATE_TAGS: 8001,
	PERM_UPDATE_DONATION_FUNDS: 11000,
	DELETE_RESTRICTION: 0,
	UPDATE_RESTRICTION: 1,
	TOGGLE_RESTRICTION: 2,
	DELETE_IMAGE: 3,
	UPDATE_IMAGE: 4,
	CHANGE_PROGRAM: 5,
	CHANGE_OWN_PASSWORD: 6,
	MANAGE_USERS: 7,
	VIEW_USERS: 8,
	VIEW_PERMISSIONS: 9,
	UPDATE_PERMISSIONS: 10,
	DELETE_PERMISSIONS: 11,
	ADD_USER: 12,
    DELETE_USER: 13,
	MODIFY_FILES: 14,
};

export const ROLES = [ 
	{ id: 1400, name: 'Update Person variables e.g. Events, Tags', description: 'Update Person variables e.g. Events, Tags' },
	{ id: 1200, name: 'Dockmaster', description: 'Dockmaster' },
	{ id: 1100, name: 'Update HS Schools and Fees', description: 'Update HS Schools and Fees' },
	{ id: 800, name: 'Update JP Variables', description: 'Update e.g. list of instructors, list of class locations' },
	{ id: 600, name: 'Manage Dock Reports', description: 'Manage Dock Reports' },
	{ id: 512, name: 'Manage Access', description: 'Create users with any access, update any user, and update who gets access to what' },
];
