export const MAGIC_NUMBERS = {
	BOAT_TYPE_ID: {
		MERCURY: 1,
		KEEL_MERCURY: 2,
		KAYAK: 3,
		RHODES_19: 4,
		SONAR: 5,
		LASER: 6,
		420: 7,
		WINDSURFER: 8,
		SUP: 9,
		IDEAL_18: 141,
		VENTURE: 1001
	},
	PROGRAM_TYPE_ID: {
		ADULT_PROGRAM: 1,
		JUNIOR_PROGRAM: 2,
		UNIVERSAL_ACCESS_PROGRAM: 3,
		HIGH_SCHOOL: 4
	},
	ACCESS_PROFILE_ID: {
		GLOBAL_ADMIN: 1
	},
	SIGNOUT_TYPE: {
		TEST: "T",
		SAIL: "S",
		CLASS: "C",
		RACE: "R"
	}
	

};